import React from "react";

function Feed({ id }) {
    let feedUrl = `https://www.gooncav.es/feeds/id/${id}`;
    // feedUrl = `http://localhost:3000/feeds/id/${id}`;

    
    return (
        <iframe
            title="Calendar"
            src={feedUrl}
            style={{
                height: `100%`,
                width: `100%`
            }}
        />
    );
}

export default Feed;
