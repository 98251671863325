import './App.css';
import MosaicApp from './MosaicApp';

function App() {
  return (
    <MosaicApp />
  );
}

export default App;
