import React from "react";
import { Mosaic, MosaicWindow } from "react-mosaic-component";
import Feed from "./Feed";
import Birdeye from "./Birdeye";
import Chart from "./Chart"

import { ButtonGroup, Button } from '@blueprintjs/core';
import { useMediaQuery } from "react-responsive";

const CustomToolbar = () => (
    <ButtonGroup minimal={true}>
        {/* <Button icon="refresh" onClick={() => alert('Refresh clicked')} />
        <Button icon="edit" onClick={() => alert('Edit clicked')} /> */}
        <a href="https://www.gooncav.es/feeds/id/825d828d-a81d-47c4-b037-2378a1001c8d" target="_blank" rel="noreferrer">
            <Button icon="link" />
        </a>
    </ButtonGroup>
);

const TITLE_MAP = {
    a: "Degens",
    b: "fejau",
    c: "Ticker mentionsp",
    new: "New Window"
};


const config = {
};

const APP_MAP = {
    a: <Feed id={"825d828d-a81d-47c4-b037-2378a1001c8d"} />,
    b: <Feed id={"74278fac-a888-49bb-a009-5ec771f7d3ee"} />,
    c: <Chart />,
    d: <Birdeye />,
    new: "New Window"
};

function WindowManager() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    console.log({isMobile})

    const initialValue = isMobile ? {
        direction: "row",
        first: "a",
        second: "b",
        splitPercentage: 50
    } : {
        direction: "row",
        first: {
            direction: "row",
            first: "a",
            second: "b",
            splitPercentage: 50
        },
        second: {
            direction: "column",
            first: "c",
            second: "d"
        }
    }

    return (
        <Mosaic
            className="mosaic-blueprint-theme bp3-dark"
            renderTile={(id, path) => (
                <MosaicWindow
                    config={config}
                    path={path}
                    title={TITLE_MAP[id]}
                    toolbarControls={<CustomToolbar />}
                // additionalControls={<CustomToolbar />}
                >
                    <div style={{ width: "100%", height: "100%" }}>{APP_MAP[id]}</div>
                </MosaicWindow>
            )}
            initialValue={initialValue}
        />
    );;
}

export default WindowManager;
