import React from "react";

function Birdeye() {
  return (
    <iframe
      title="Birdeye"
      src="https://birdeye.so/token/8t5FggSQmHR8Q3xKg6dpopdYQRMGjWmqD2k7cb2HmguX?chain=solana"
      style={{
        height: `100%`,
        width: `100%`
      }}
    />
  );
}

export default Birdeye;
